export const CONSTANT = {
  URL_STORE: [
    "https://www.facebook.com",
    "https://www.youtube.com/watch?v=EJ_ehqu7vJM&list=RDEJ_ehqu7vJM&start_radio=1",
    "https://www.scholarship.in.th/16-make-money-without-doing-anything/",
    "https://th.wikipedia.org/wiki/สงครามโลกครั้งที่สอง",
    "https://shopee.co.th",
    "https://travel.kapook.com/view207126.html",
    "https://www.google.com/search?q=boa+hancock+skyline+weight+%3F&client=opera&hs=yDz&sxsrf=ALeKk00QIzqQX_YkhirEgPjBVcwqQEoeSg:1629351797384&source=lnms&tbm=isch&sa=X&ved=2ahUKEwih4OK4sLzyAhXHyzgGHTnoDhUQ_AUoAXoECAEQAw&biw=1880&bih=980",
    "https://www.youtube.com/watch?v=YooUwJRI6NI",
    "https://www.google.com/search?q=covid+ไทยวันนี้&client=opera&sxsrf=ALeKk0162Sw6fG7fdAh933hS5eATanXfag%3A1629352075516&ei=i_AdYZnxHue_4-EP8PeKmAo&oq=covid&gs_lcp=Cgdnd3Mtd2l6EAMYADIGCCMQJxATMgoIABCxAxCDARBDMgoIABCxAxCDARBDMgoIABCxAxCDARBDMgoIABCxAxCDARBDMgoIABCxAxCDARBDMgQIABBDMgoIABCxAxCDARBDMgsIABCxAxCDARDJAzIFCAAQkgM6CAgAELADEMsBOgQIIxAnOgsILhCABBDHARCjAjoKCAAQgAQQhwIQFDoLCAAQgAQQsQMQgwE6CwguEIAEEMcBEK8BOgUIABCABDoFCC4QgAQ6BwgjEOoCECc6BQgAEJECOggIABCxAxCDAUoECEEYAVDXCljgFmChH2gDcAB4AIABbYgB_AWSAQM1LjOYAQCgAQGwAQrIAQrAAQE&sclient=gws-wiz",
    "https://www.youtube.com/watch?v=i3GN4vLgP8s&list=RDGMEMQ1dJ7wXfLlqCjwV0xfSNbAVMi3GN4vLgP8s&start_radio=1",
  ],
  EXCEPT: ["type", "bonus_type"],
  ROLE: { ADMIN: "admin", BOT: "bot", DEV: "developer" },
};

export const PUBLIC_IMAGES_URL = {
  BANK: `${window.env.REACT_APP_DEV_ENDPOINT}/images/bank`,
  PROMOTION: `${window.env.REACT_APP_DEV_ENDPOINT}/images/promotion`,
  CONFIG: `${window.env.REACT_APP_DEV_ENDPOINT}/images/config`,
};

export const STATEMENT_STATUS = {
  FAILED: "failed",
  WAITING: "wating",
  IN_PROCESS: "inprocess",
  SUCCESS: "success",
  UNKNOWN: "unknown",
};

export const PERMISSION_KEY = {
  MEMBER_CREDIT: "member.list.credit",
  MEMBER_EXP: "member.list.exp",
  MEMBER_POINT: "member.list.point",
  MEMBER_TURN: "member.list.turn",
  MEMBER_FIXED_DEPOSIT: "member.list.fixeddeposit",
};

export const REWARD_REQUEST_TYPE = {
  APPROVED: "approved",
  REJECTED: "reject",
  PENDING: "pending",
};

export const WITHDRAW_TYPE = {
  MANUAL: "withdraw_manual",
  AUTO: "withdraw_auto",
};

export const TRANSACTION_STATUS = {
  SUCCESS: "withdraw_success",
  WAITING: "withdraw_waiting",
  CANCEL: "cancel",
};

export const CONFIG_RULES = {
  FIXED_DEPOSIT_SWITCH: "001",
  POINT_TURN_OVER: "002",
  MIN_TOPUP: "003",
  MIN_WITHDRAW: "004",
  MANUAL_SET_MAX_BALANCE: "005",
  AUTO_MIN_WITHDRAW: "006",
  AUTO_MAX_WITHDRAW: "007",
  POINT_TO_BALANCE: "008",
  BALANCE_TO_POINT: "009",
  POINT_TO_SPIN: "010",
  REFUND_CREDIT_PERCENT: "011",
  QUICK_AUTO_TRANSFER: "012",
  SERVER_STATUS: "013",
  SERVER_MESSAGE: "014",
  SERVER_MESSAGE_ADMIN: "015",
  SERVER_LINE_CONTACT: "016",
  MAX_FRIEND_INVITE: "017",
  PERCENT_FROM_INVITE: "018",
  FREE_CREDIT: "019",
  MIN_RECEIVE_BALANCE: "020",
  AFFILIATE_DETAIL: "021",
  DAY_START: "022",
  DAY_END: "023",
  NIGHT_START: "024",
  NIGHT_END: "025",
  AFTERNOON_START: "026",
  AFTERNOON_END: "027",
  JSON_DATA: "028",
  BACKGROUND_IMAGE: "029",
  AVATAR_IMAGE: "030",
  FIXED_DEPOSIT_IMAGE: "031",
  ICON_IMAGE: "032",
  FIXED_DEPOSIT_VALUE: "033",
  SYSTEM_BALANCE: "034",
  LINE_TOKEN: "035",
  RICH_LOGIN: "036",
  RICH_NO_LOGIN: "037",
  JACKPOT_WHEEL_STATUS: "038",
  BANNER_IMAGE: "039",
  MEMBER_LOGIN_BACKGROUND_IMAGE: "040",
  MEMBER_LOBBY_BACKGROUND_IMAGE: "041",
  BET_OR_LOSE: "042",
  ADVANCED_PERCENT_SLOT: "043",
  ADVANCED_PERCENT_BINGO: "044",
  ADVANCED_PERCENT_FISHING: "045",
  ADVANCED_PERCENT_ECASINO: "046",
  ADVANCED_PERCENT_LIVE_CASINO: "047",
  ADVANCED_PERCENT_SCRATCH_CARD: "048",
  ADVANCED_PERCENT_ARCADE: "049",
  ADVANCED_PERCENT_TABLE_GAME: "050",
  ADVANCED_PERCENT_KENO: "051",
  ADVANCED_PERCENT_OTHER_GAME: "052",
  ADVANCED_PERCENT_ALTERNATIVE: "053",
  MEMBER_MODAL_LOGGED_IN: "055",
  FIXED_DEPOSIT_TURNOVER: "056",
  WHEEL_TURNOVER: "058",
  REWARD_TURNOVER: "059",
  COMM_STATUS: "060",
  CASHBACK_STATUS: "061",
  REFUND_WEEK_SWITCH: "062",
  COMM_PYRAMID_STATUS: "063",
  COMM_PYRAMID_LV2: "064",
  COMM_PYRAMID_LV3: "065",
  PRO_TYPE: "066",
  TYPE_OF_AFFILIATE_RECEIVE: "067",
  AFFILIATE_DEPOSIT_STATUS: "068",
  ADVANCED_RECEIVE_STATUS: "069",
  ADVANCED_RECEIVE_SLOT: "070",
  ADVANCED_RECEIVE_CASINO: "071",
  ADVANCED_RECEIVE_FISH: "072",
  ADVANCED_RECEIVE_SPORT: "073",
  REFUND_CREDIT_MAX: "074",
  REFUND_TURNOVER: "075",
  DEPOSIT_TURN: "076", //deposit_turn
  DEPOSIT_TURN_PERCENT: "077", //deposit_turn_percent
  WITHDRAW_NEW_MEMBER: "078", //withdraw_new_member
  CREADIT_FREE_WITHDRAW_AUTO: "081",
  MEMBER_MODAL_LOGGED_IN_LINK: "082",
  LINK_FREE_CREDIT: "090",
  OPEN_ACTIVITIES: "098",
  CLOSE_ACTIVITIES: "099",
  KIP_EXCHANGE_RATE: "097",
  USE_ALL_SLIPS: "096",
  WHATS_APP_CONTACT: "095",

  CONFIG_OPEN_CAMPAIGN: "100",
  CONFIG_CAMPAIGN_NAME: "101",
  CONFIG_CAMPAIGN_TURN_OVER: "102",
  CONFIG_CAMPAIGN_BONUS: "103",
  CONFIG_CAMPAIGN_MINIMUM_DEPOSIT: "104",
  CONFIG_CAMPAIGN_TYPE_TURN_OVER: "105",
  CONFIG_CAMPAIGN_IMAGE: "106",
  CONFIG_CAMPAIGN_DESCRIPTION: "107",
  MEMBER_LINE_GROUP_LINK_1: "108",
  MEMBER_LINE_GROUP_IMAGE_1: "109",
  MEMBER_LINE_GROUP_LINK_2: "110",
  MEMBER_LINE_GROUP_IMAGE_2: "111",
  MEMBER_LINE_GROUP_LINK_3: "112",
  MEMBER_LINE_GROUP_IMAGE_3: "113",
  MEMBER_LINE_GROUP_LINK_4: "114",
  MEMBER_LINE_GROUP_IMAGE_4: "115",
  MEMBER_LINE_GROUP_LINK_5: "116",
  MEMBER_LINE_GROUP_IMAGE_5: "117",
  MEMBER_SOCIAL: "118",
  TYPE_WINLOST: "119",
  ANNOUNCE_UI_V5: "120",
  TWITTER_CONTACT: "125",

  URL_REGISTER: "126",
  URL_CONTACT: "127",
  URL_RECEIVED_CREDITFREE: "128",
  URL_EDITOR: "129",
  URL_EDITOR_DEPOSIT: "130",
  URL_EDITOR_OPEN: "131",
  URL_EDITOR_DEPOSIT_OPEN: "132",
  AFF_AMOUNT_LIMIT: "133",
  AFF_TURN_AMOUNT: "134",
  AFF_TURN_TYPE: "135",
};
